<template>
	<v-row class="mt-2">
		<v-col cols="8" class="ml-6">
			<div class="text-h6 font-weight-black">{{ title }}</div>
		</v-col>
		<v-col cols="2" class="ml-6">
			<v-btn @click="closePanel" depressed icon height="32" width="32" class="closebtn ml-auto" :title="closeTitleBtn">
				<v-icon>far fa-times</v-icon>
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
import { REQUESTEDS_PANEL_ID } from '@/lib/variables/panels';
export default {
	props: ['closePanel', 'type'],

	computed: {
		title() {
			if (this.type == REQUESTEDS_PANEL_ID) {
				return this.$t('shipsituation.resquesteds.authorization.title');
			} else {
				return this.$t('shipsituation.planning.title');
			}
		},

		closeTitleBtn() {
			return this.$t('common.closeTitleBtn');
		}
	}
};
</script>

<style lang="postcss">
.closebtn {
	background-color: var(--low);

	& i {
		color: var(--primary);
	}
	&:hover {
		& i {
			color: var(--mapfre-accent);
		}
	}
}
.v-application .text-h6 {
	font-family: Overpass, Montserrat, sans-serif !important;
}
</style>
