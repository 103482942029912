<template>
	<v-form ref="form" v-model="valid" lazy-validation>
		<v-row dense>
			<v-row class="pa-6 pt-1 pb-0 mt-6" dense>
				<v-col cols="12">
					<operations-field :label="$t('operations.stops.vesselname')" :value="stop.vesselname" :inline="true"></operations-field>
				</v-col>
				<v-col cols="12">
					<operations-field
						:label="$t('operations.stops.vesseltype')"
						:value="stop.vesseltypedescription"
						:inline="true"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.vessellength')"
						:value="stop.vessellength"
						:inline="true"
						suffix="m"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.vesselbeam')"
						:value="stop.vesselbeam"
						:inline="true"
						suffix="m"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.draftntrance')"
						:value="stop.draftentrance ? stop.draftentrance : 0"
						:inline="true"
						suffix="m"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.draftoutlet')"
						:value="stop.draftoutlet ? stop.draftoutlet : 0"
						:inline="true"
						suffix="m"
					></operations-field>
				</v-col>
			</v-row>
			<v-col cols="12">
				<v-row class="d-flex align-center">
					<v-col cols="1"><v-divider></v-divider></v-col>
					<v-col cols="6">
						<legend class="font-weight-black text-h6" style="color: var(--primary)">
							{{ $t('shipsituation.resquesteds.authorization.stop') }}
						</legend>
					</v-col>
					<v-col cols="5"><v-divider></v-divider></v-col>
				</v-row>

				<v-row class="pa-6 pt-1 pb-0" dense>
					<v-col cols="6">
						<operations-field :label="$t('operations.stops.portcallnumber')" :value="stop.portcallnumber"></operations-field>
					</v-col>
					<v-col cols="6">
						<operations-field :label="$t('operations.stops.num')" :value="stop.num"></operations-field>
					</v-col>
					<v-col cols="12">
						<pui-select
							:id="`authorizations-berth`"
							:attach="`authorizations-berth`"
							:label="$t('operations.stops.berthname')"
							toplabel
							v-model="berth"
							required
							modelName="berth"
							:modelFormMapping="{ id: 'berth', berthcode: 'berthcode' }"
							:itemsToSelect="[{ id: berth.berth, berthcode: berth.berthcode }]"
							:itemValue="['id', 'berthcode']"
							:itemText="(item) => `${item.name}`"
							:order="{ name: 'asc' }"
							reactive
							@change="changeBerth"
							:fixedFilter="filterByGeomNotNull"
							:disabled="processVesselsRunning"
						></pui-select>
					</v-col>
					<v-col cols="6">
						<operations-field :label="$t('operations.stops.berthdraugth')" :value="draugth"></operations-field>
					</v-col>
					<v-col cols="6">
						<operations-field
							:label="$t('operations.stops.bollards')"
							:value="((initbol !== null ? initbol : '') && (endbol !== null ? initbol + ' - ' + endbol : '')) || ''"
						></operations-field>
					</v-col>
					<v-col cols="6">
						<pui-select
							:key="selectKeyBollardIni"
							v-show="!processVesselsRunning"
							:id="`authorizations-bollardIni`"
							:attach="`authorizations-bollardIni`"
							:label="$t('operations.stops.initialBollard')"
							toplabel
							v-model="authorization"
							modelName="bollard"
							:itemsToSelect="[{ bolcode: this.authorization.bollardIni }]"
							:modelFormMapping="{ bolcode: 'bollardIni' }"
							itemValue="bolcode"
							itemText="bolcode"
							:order="{ orderby: 'asc' }"
							reactive
							:fixedFilter="bollardIniFixedFilter"
							:rules="bollardRules"
						></pui-select>
					</v-col>
					<v-col cols="6">
						<pui-select
							:key="selectKeyBollardEnd"
							v-show="!processVesselsRunning"
							:id="`authorizations-bollardEnd`"
							:attach="`authorizations-bollardEnd`"
							:label="$t('operations.stops.endBollard')"
							toplabel
							v-model="authorization"
							modelName="bollard"
							:itemsToSelect="[{ bolcode: this.authorization.bollardEnd }]"
							:modelFormMapping="{ bolcode: 'bollardEnd' }"
							itemValue="bolcode"
							itemText="bolcode"
							:order="{ orderby: 'asc' }"
							reactive
							:fixedFilter="bollardEndFixedFilter"
							:rules="bollardRules"
						></pui-select>
					</v-col>
					<v-col cols="12">
						<pui-select
							id="portid-contact"
							attach="portid-contact"
							:label="$t('operations.stops.berthtype')"
							toplabel
							required
							v-model="berthType"
							modelName="berthingtype"
							:modelFormMapping="{
								berthingtypecode: 'berthingtypecode',
								docksideways: 'docksideways',
								portstarboard: 'portstarboard',
								bowstern: 'bowstern',
								berthingtypename: 'berthingtypename'
							}"
							:itemsToSelect="[
								{
									berthingtypecode: berthType.berthingtypecode,
									docksideways: berthType.docksideways,
									portstarboard: berthType.portstarboard,
									bowstern: berthType.bowstern,
									berthingtypename: berthType.berthingtypename
								}
							]"
							:itemValue="['berthingtypecode', 'docksideways', 'portstarboard', 'bowstern', 'berthingtypename']"
							:itemText="(item) => `${item.berthingtypename}`"
							:order="{ berthingtypename: 'asc' }"
							reactive
							clearable
							@change="changeBerthForm"
							:fixedFilter="filterByReal"
							:disabled="processVesselsRunning"
						></pui-select>
					</v-col>
					<!--<v-col cols="12">
					<pui-select
						:label="$t('operations.stops.berthingposition')"
						itemText="text"
						itemValue="value"
						toplabel
						:itemsToSelect="[{ value: authorization.berthingposition }]"
						:items="berthingsPositions"
						v-model="berthingposition"
						clearable
						reactive
						:key="berthingpositionKey"
					>
					</pui-select>
				</v-col>-->
					<!--<v-col cols="6">
					<pui-checkbox :label="$t('shipsituation.resquesteds.authorization.abarloado')" v-model="authorization.abarloado"></pui-checkbox>
				</v-col>-->
					<v-col cols="12">
						<pui-text-area
							:id="`place-operation`"
							v-model="authorization.observations"
							:label="$t('shipsituation.resquesteds.authorization.observations')"
							toplabel
							@focusout.native="updateObservationsField"
							:disabled="processVesselsRunning"
						></pui-text-area>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<v-row class="d-flex align-center" dense>
					<v-col cols="1"><v-divider></v-divider></v-col>
					<v-col cols="6">
						<legend class="font-weight-black text-h6" style="color: var(--primary)">
							{{ $t('shipsituation.resquesteds.authorization.stopDates') }}
						</legend>
					</v-col>
					<v-col cols="5"><v-divider></v-divider></v-col>
				</v-row>

				<v-row class="pa-6 pt-1 pb-0" dense>
					<v-col cols="12">
						<operations-field label="ETA" :value="convertDatesFromStopAIS(stop.eta)" :inline="true"></operations-field>
					</v-col>
					<v-col cols="12" v-if="stop.pbaestimated">
						<operations-field
							:label="$t('stop.pbaestimated')"
							:value="convertDatesFromStopAIS(stop.pbaestimated)"
							:inline="true"
						></operations-field>
					</v-col>
					<v-col cols="12">
						<pui-date-field
							:id="`stopPlanned-authorization`"
							v-model="authorization.entryPlanned"
							:label="$t('shipsituation.resquesteds.authorization.stopPlanned')"
							toplabel
							required
							time
							@input="updateEntryDate"
							:disabled="processVesselsRunning"
						></pui-date-field>
					</v-col>
					<v-col cols="12" v-if="stop.pbaestimated">
						<pui-date-field
							:id="`pbaauthorized-stop`"
							v-model="stop.pbaauthorized"
							:label="$t('stop.pbaauthorized')"
							toplabel
							required
							time
							:key="keyPbaAuth"
							:disabled="processVesselsRunning"
						></pui-date-field>
					</v-col>
					<v-col cols="12">
						<operations-field label="ETD" :value="convertDatesFromStopAIS(stop.etd)" :inline="true"></operations-field>
					</v-col>
					<v-col cols="12">
						<pui-date-field
							:id="`departurePlanned-stopblock`"
							v-model="authorization.departurePlanned"
							:label="$t('shipsituation.resquesteds.authorization.departurePlanned')"
							toplabel
							required
							:min="authorization.entryPlanned"
							time
							@input="updateDepartureDate"
							:disabled="processVesselsRunning"
						></pui-date-field>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12">
				<v-row>
					<v-divider></v-divider>
				</v-row>
				<v-row class="d-flex justify-space-around mt-9 mb-3">
					<v-btn v-if="isPlanningPanelId && $store.state.mapconfig.portAutorityHaveStatusPl" color="primary" @click="planBtn()">{{
						$t('shipsituation.planning.plan')
					}}</v-btn>
					<v-btn v-else color="primary" @click="authorizationBtn()">{{ $t('shipsituation.resquesteds.authorization.authorize') }}</v-btn>
					<!-- <v-btn color="#dadde0" @click="cleanStopToInitialData">{{ $t('shipsituation.resquesteds.authorization.clean') }}</v-btn> -->
				</v-row>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import OperationsField from './OperationsField.vue';
import { STOP_INSTANT_LAYER_ID, BOLLARDS_LAYER_ID, BERTHS_LAYER_ID } from '@/components/operations/map/constants/layers';
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';
import { PLANNING_PANEL_ID } from '@/lib/variables/panels';
import StopsServiceMixin from '@/mixins/StopsServiceMixin';

export default {
	components: { OperationsField },
	mixins: [StopsServiceMixin],
	props: ['closePanel', 'stop', 'type'],
	data() {
		return {
			selectKeyBollardIni: 0,
			selectKeyBollardEnd: 0,
			stopId: null,
			isStartTabBerth: false,
			isStartTabBollardIni: false,
			isStartTabBollardEnd: false,
			isStartTabEtaAuth: false,
			isStartTabEtdAuth: false,
			valid: true,
			authorization: {},
			berth: {},
			initbol: 0,
			endbol: 0,
			draugth: 0,
			berthType: {},
			berthingsPositions: [
				{
					text: this.$t('shipsituation.resquesteds.authorization.postionNoDetails'),
					value: 'DOCKED-NO_DETAILS'
				},
				{
					text: this.$t('shipsituation.resquesteds.authorization.positionStardboard'),
					value: 'DOCKED-STARBOARD'
				},
				{
					text: this.$t('shipsituation.resquesteds.authorization.positionPort'),
					value: 'DOCKED-PORT'
				}
			],
			berthingpositionKey: 0,
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat,
			dockid: 0,
			bollardOrderIni: 0,
			bollardOrderFin: 1,
			keyPbaAuth: 0
		};
	},
	computed: {
		bollardRules() {
			return [this.bollardOrderIni <= this.bollardOrderFin || this.$t('stop.bollardsException')];
		},
		processVesselsRunning() {
			return this.$store.getters.getProcessVesselsRunning;
		},
		activePanelOptionsPeriods() {
			return this.$store.getters.activePanelOptionsPeriods;
		},
		isPlanningPanelId() {
			if (this.type == PLANNING_PANEL_ID) {
				return true;
			}
			return false;
		},
		filterByGeomNotNull() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'thegeom', op: 'nn' }]
			};
		},
		filterByReal() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'real', op: 'eq', data: true }]
			};
		},
		bollardIniFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'berthid', op: 'eq', data: this.berth.berth },
					{ field: 'thegeom', op: 'nn' }
				]
			};
		},
		bollardEndFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					this.dockid ? { field: 'dockid', op: 'eq', data: this.dockid } : { field: 'berthid', op: 'eq', data: this.berth.berth },
					{ field: 'thegeom', op: 'nn' }
				]
			};
		}
	},
	created() {
		this.setInitialData();
		this.isStartTabBerth = true;
		this.isStartTabBerthForm = true;
		this.isStartTabBollardIni = true;
		this.isStartTabBollardEnd = true;
		if (!this.stop.berthid) {
			this.isStartTabBerth = false;
		}
		if (!this.stop.berthingtype) {
			this.isStartTabBerthForm = false;
		}
		if (!this.stop.inibollard) {
			this.isStartTabBollardIni = false;
		}
		if (!this.stop.endbollard) {
			this.isStartTabBollardEnd = false;
		}
		this.isStartTabEtaAuth = true;
		this.isStartTabEtdAuth = true;

		this.$puiEvents.$on('onPuiSelect_selectedItems-authorizations-bollardIni', (newVal) => {
			if (!this.isStartTabBollardIni) {
				this.updateBolardsVesselLayerIni();
			}

			var lupaModel = newVal.model;
			if (lupaModel) {
				this.bollardOrderIni = lupaModel.orderby;
			} else {
				this.bollardOrderIni = 0;
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-authorizations-bollardEnd', (newVal) => {
			if (!this.isStartTabBollardFin) {
				this.updateBolardsVesselLayerEnd();
			}

			var lupaModel = newVal.model;
			if (lupaModel) {
				this.bollardOrderFin = lupaModel.orderby;
			} else {
				this.bollardOrderFin = 1;
			}
		});

		this.$puiEvents.$on('onPuiSelect_selectedItems-authorizations-berth', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.dockid = lupaModel.dockid;
			} else {
				this.dockid = null;
			}
		});
	},
	destroyed() {
		this.$store.commit('setProcessVesselsRunning', false);
		this.$puiEvents.$off('onPuiSelect_selectedItems-authorizations-bollardIni');
		this.$puiEvents.$off('onPuiSelect_selectedItems-authorizations-bollardEnd');
		this.$puiEvents.$off('onPuiSelect_selectedItems-authorizations-berth');
	},
	methods: {
		setInitialData() {
			this.stopId = this.stop.id;
			this.berthingpositionKey++;
			this.berth = {
				berth: this.stop.berthid,
				berthcode: this.stop.berthcode
			};
			this.berthType = {
				berthingtypecode: this.stop.berthingtype,
				docksideways: this.stop.docksideways,
				portstarboard: this.stop.portstarboard,
				bowstern: this.stop.bowstern
			};
			this.authorization.berthingposition = this.stop.berthingposition;
			this.authorization.vesselimo = this.stop.vesselimo;
			this.authorization.observations = this.stop.observations;
			if (this.stop.statusid == 'AU') {
				this.stop.entrydate = this.stop.entrydate != null ? this.stop.entrydate : this.stop.etaauth;
				this.stop.destinationdate = this.stop.destinationdate != null ? this.stop.destinationdate : this.stop.etdauth;
			} else {
				this.stop.entrydate = this.stop.entrydate != null ? this.stop.entrydate : this.stop.eta;
				this.stop.destinationdate = this.stop.destinationdate != null ? this.stop.destinationdate : this.stop.etd;
			}

			this.authorization.entryPlanned = this.stop.entrydate;
			this.authorization.departurePlanned = this.stop.destinationdate;
			const portId = window.localStorage.getItem('workingPortsId');
			this.dockid = null;
			this.dockid = JSON.parse(window.localStorage.getItem(`cartoberths-${portId}`)).find((berth) => berth.id == this.stop.berthid).dockid;
			if (this.stop.pbaestimated) {
				this.calculatePba();
			}
			this.authorization.bollardIni = this.stop.inibollard;
			this.authorization.bollardEnd = this.stop.endbollard;
			this.selectKeyBollardIni++;
			this.selectKeyBollardEnd++;
		},
		planBtn() {
			this.$refs.form.validate();
			console.log('TODO');
			const url = '/visits/updateStop'; // ¿?
		},
		authorizationBtn() {
			if (!this.$refs.form.validate()) {
				return;
			}
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' - ' + this.$puiI18n.t('shipsituation.resquesteds.authorization.title');
			if (this.berth.berth && this.authorization.entryPlanned && this.authorization.departurePlanned && this.berthType.berthingtypecode) {
				//LLAMADA PMS (PUERTO, AÑO, CODESCALA, CODESTADIA, MUELLE, BOLARDOS, FECHAS, TIPO ATRAQUE, OBS)
				let url = '/pms/authorizeStops';
				let toAuth = [];

				toAuth.push({
					portAuthorityId: this.stop.portauthority,
					portCode: this.stop.portcode,
					portCallNumber: this.stop.portcallnumber,
					stopNumber: this.stop.num,
					berthCode: this.berth.berthcode,
					bollardIni: this.authorization.bollardIni,
					bollardEnd: this.authorization.bollardEnd,
					etaAuth: this.authorization.entryPlanned,
					etdAuth: this.authorization.departurePlanned,
					berthingType: this.berthType.berthingtypecode,
					comments: this.authorization.observations,
					pbaAuthorized: this.stop.pbaauthorized
				});
				console.log(toAuth);
				this.$puiRequests.postRequest(
					url,
					toAuth,
					(response) => {
						// Clean arrays
						this.$store.commit('setStopsFeaturesInstantAU', []);
						this.$store.commit('setStopsFeaturesInstantModifiedAU', []);
						this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
						this.$store.commit('setStopsFeaturesInstantRemovedAU', []);
						this.$puiNotify.success(this.$t('pui9.save.success'));

						// Cerrar el panel y automaticamente se refrescara el mapa ya que se carga una nueva pestaña
						this.closePanel();
					},
					(error) => {
						this.$puiNotify.error(error.response.data.message, title);
					}
				);
			} else {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.resquesteds.authorization.error'), title);
			}
		},
		async changeBerth() {
			const opts = {
				model: 'berth',
				page: 1,
				rows: 1,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'id', op: 'eq', data: this.berth.berth }]
				}
			};

			const response = await this.$puiRequests.postRequest('/puisearch', opts);
			this.initbol = response.data.data[0].initbolcode;
			this.endbol = response.data.data[0].endbolcode;
			this.draugth = response.data.data[0].draught;

			//this.dockid = response.data.data[0].dockid;
			if (this.draugth != null && this.stop.vesseldraugth > this.draugth) {
				this.$puiNotify.error(`${this.$t('operations.draugth_bigger')}`);
				// Volvemos a poner los datos anteriores
				this.berth = {
					berth: this.stop.berthid,
					berthcode: this.stop.berthcode
				};
				this.berthType = {
					berthingtypecode: this.stop.berthingtype,
					docksideways: this.stop.docksideways,
					portstarboard: this.stop.portstarboard,
					bowstern: this.stop.bowstern
				};
				this.authorization.bollardIni = this.stop.inibollard;
				this.authorization.bollardEnd = this.stop.endbollard;
				this.authorization.berthingposition = this.stop.berthingposition;
				this.authorization.vesselimo = this.stop.vesselimo;
			} else {
				if (this.berth && (this.berth.berth !== this.stop.berthid || this.berth.berthcode !== this.stop.berthcode)) {
					this.authorization.bollardIni = this.initbol;
					this.authorization.bollardEnd = this.endbol;
					this.updateBerthVesselLayer();
				} else {
					if (!this.isStartTabBerth) {
						this.authorization.bollardIni = this.stop.inibollard;
						this.authorization.bollardEnd = this.stop.endbollard;
					}
				}
				this.isStartTabBerth = false;
			}
		},

		changeBerthForm() {
			if (
				this.berthType &&
				this.berthType.berthingtypecode &&
				this.berthType.berthingtypecode !== this.stop.berthingtype &&
				!this.isStartTabBerthForm
			) {
				this.updateBerthTypeVesselLayer();
			}
			this.isStartTabBerthForm = false;
		},

		updateBerthTypeVesselLayer() {
			// Hay que enviar el stop modificado
			this.stop.berthingtype = this.berthType.berthingtypecode;
			this.stop.berthingtypename = this.berthType.berthingtypename;
			this.stop.docksideways = this.berthType.docksideways;
			this.stop.portstarboard = this.berthType.portstarboard;
			this.stop.bowstern = this.berthType.bowstern;
			this.stop.idLayer = STOP_INSTANT_LAYER_ID;
			this.stop.modified = true;

			// Subimos el stop modificado y los arrays que no estan modificados
			const stops = this.updateStopsModifications(this.stop);
			this.stopsFeatureCreatorUpdated(stops);
		},

		updateBerthVesselLayer() {
			// Hay que enviar el stop modificado
			this.stop.inibollard = this.authorization.bollardIni;
			this.stop.endbollard = this.authorization.bollardEnd;
			this.stop.idLayer = STOP_INSTANT_LAYER_ID;
			this.stop.modified = true;

			// Cambiar los numeros de bolardos segun el code.
			//getFeaturesLayerByPropertyValue
			var bollardIniFeature = ol2map.getBollardsByCode(
				BOLLARDS_LAYER_ID,
				this.berth.berth,
				this.stop.inibollard,
				this.stop.portid,
				this.dockid
			);
			var bollardEndFeature = ol2map.getBollardsByCode(
				BOLLARDS_LAYER_ID,
				this.berth.berth,
				this.stop.endbollard,
				this.stop.portid,
				this.dockid
			);
			if (bollardIniFeature && bollardEndFeature) {
				this.stop.bollardini = bollardIniFeature.get('id');
				this.stop.bollardend = bollardEndFeature.get('id');
			} else {
				this.stop.bollardini = '0';
				this.stop.bollardend = '0';
			}
			this.stop.berthid = this.berth.berth;
			this.stop.berthcode = this.berth.berthcode;
			this.stop.modified = true;

			const stops = this.updateStopsModifications(this.stop);
			this.stopsFeatureCreatorUpdated(stops);

			// Zoom to the berth
			const berthFeature = ol2map.getBerthById(BERTHS_LAYER_ID, this.berth.berth);
			const offset = 100;
			if (berthFeature) {
				var extent = berthFeature.getGeometry().getExtent();
				var extentOffset = [extent[0] - offset, extent[1] - offset, extent[2] + offset, extent[3] + offset];
				ol2map.zoomToExtent(extentOffset);
			}
		},
		updateBolardsVesselLayerIni() {
			if (!this.isStartTabBollardIni && this.authorization.bollardIni) {
				this.updateBolardsVesselLayer();
			}
			this.isStartTabBollardIni = false;
		},
		updateBolardsVesselLayerEnd() {
			if (!this.isStartTabBollardEnd && this.authorization.bollardEnd) {
				this.updateBolardsVesselLayer();
			}
			this.isStartTabBollardEnd = false;
		},
		updateObservationsField() {
			this.stop.modified = true;
			this.stop.observations = this.authorization.observations;
			const stops = this.updateStopsModifications(this.stop);
			this.stopsFeatureCreatorUpdated(stops);
		},
		updateEntryDate() {
			if (!this.isStartTabEtaAuth) {
				this.stop.modified = true;
				this.stop.entrydate = this.authorization.entryPlanned;
				if (this.stop.pbaestimated) {
					this.calculatePba();
				}
			}
			this.isStartTabEtaAuth = false;
		},
		calculatePba() {
			console.log('pasa 2');

			let url = '/locationsequenceheader/calculatePBAauth';
			let stop = {
				portAuthorityId: this.stop.portauthority,
				portCode: this.stop.portcode,
				portCallNumber: this.stop.portcallnumber,
				stopNumber: this.stop.num,
				berthCode: this.berth.berthcode,
				bollardIni: this.authorization.bollardIni,
				bollardEnd: this.authorization.bollardEnd,
				etaAuth: this.authorization.entryPlanned,
				etdAuth: this.authorization.departurePlanned,
				berthingType: this.berthType.berthingtypecode,
				comments: this.authorization.observations
			};
			this.$puiRequests.postRequest(
				url,
				stop,
				(response) => {
					this.stop.pbaauthorized = response.data;
					this.keyPbaAuth++;
				},
				(error) => {
					this.$puiNotify.error(error.response.data.message);
				},
				null
			);
		},
		updateDepartureDate() {
			if (!this.isStartTabEtdAuth) {
				this.stop.modified = true;
				this.stop.destinationdate = this.authorization.departurePlanned;
				const stops = this.updateStopsModifications(this.stop);
				this.stopsFeatureCreatorUpdated(stops);
			}
			this.isStartTabEtdAuth = false;
		},
		updateBolardsVesselLayer() {
			// Cambiar los numeros de bolardos segun el code.
			if (this.authorization.bollardIni && this.authorization.bollardEnd) {
				var bollardIniFeature = ol2map.getBollardsByCode(
					BOLLARDS_LAYER_ID,
					this.stop.berthid,
					this.authorization.bollardIni.toString(),
					this.stop.portid,
					this.dockid
				);
				var bollardEndFeature = ol2map.getBollardsByCode(
					BOLLARDS_LAYER_ID,
					this.stop.berthid,
					this.authorization.bollardEnd.toString(),
					this.stop.portid,
					this.dockid
				);
				if (bollardIniFeature && bollardEndFeature) {
					// Hay que enviar el stop modificado
					this.stop.bollardini = bollardIniFeature.get('id');
					this.stop.bollardend = bollardEndFeature.get('id');
					this.stop.inibollard = this.authorization.bollardIni.toString();
					this.stop.endbollard = this.authorization.bollardEnd.toString();
					this.stop.idLayer = STOP_INSTANT_LAYER_ID;
					this.stop.modified = true;
					const stops = this.updateStopsModifications(this.stop);
					this.stopsFeatureCreatorUpdated(stops);
				}
			}
		},

		updateStopsModifications(stopmodified, cleanStop = false) {
			var self = this;
			var idsStopsModified = null;
			var stopsModified = null;
			if (this.isPlanningPanelId) {
				idsStopsModified = this.$store.getters.getStopsFeaturesInstantModifiedPL.filter((stop) => 'id' in stop).map((stop) => stop['id']);
				stopsModified = this.$store.getters.getStopsFeaturesInstantModifiedPL;
			} else {
				idsStopsModified = this.$store.getters.getStopsFeaturesInstantModifiedAU.filter((stop) => 'id' in stop).map((stop) => stop['id']);
				stopsModified = this.$store.getters.getStopsFeaturesInstantModifiedAU;
			}

			if (idsStopsModified.includes(stopmodified.id)) {
				// Valor ya estaba modificado
				stopsModified = stopsModified.map((stop) => {
					if (stop.id === stopmodified.id) {
						if (!cleanStop) {
							// Solo devolvemos el stop si no queremos limpiarlo
							// Reemplaza el objeto por que el hemos modificado
							return stopmodified;
						}
					} else {
						return stop; // Mantiene el objeto sin cambios
					}
				});
				stopsModified = stopsModified.filter((element) => element !== undefined);
			} else {
				if (!cleanStop) {
					stopsModified.push(stopmodified);
					idsStopsModified.push(stopmodified.id);
				}
			}

			var featuresInstantWithoutStopsModifiying = null;

			// Subimos los stops modificados al state
			if (this.isPlanningPanelId) {
				this.$store.commit('setStopsFeaturesInstantModifiedPL', stopsModified);
				if (cleanStop) {
					featuresInstantWithoutStopsModifiying = this.$store.getters.getStopsFeaturesInstantPL;
				} else {
					featuresInstantWithoutStopsModifiying = this.$store.getters.getStopsFeaturesInstantPL.filter((stop) => {
						return !idsStopsModified.includes(stop.id);
					});
				}
			} else {
				this.$store.commit('setStopsFeaturesInstantModifiedAU', stopsModified);
				if (cleanStop) {
					featuresInstantWithoutStopsModifiying = this.$store.getters.getStopsFeaturesInstantAU;
				} else {
					featuresInstantWithoutStopsModifiying = this.$store.getters.getStopsFeaturesInstantAU.filter((stop) => {
						return self.cleanStop ? true : !idsStopsModified.includes(stop.id);
					});
				}
			}

			const cleanFeatures = this.cleanStopsToProcess([...featuresInstantWithoutStopsModifiying, ...stopsModified]);
			return cleanFeatures;
		},

		convertDatesFromStopAIS(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		},

		cleanStopToInitialData() {
			var stopOriginal = null;
			if (this.isPlanningPanelId) {
				stopOriginal = this.$store.getters.getStopsFeaturesInstantWithModifiedPL.find((st) => st.id === this.stop.id);
			} else {
				stopOriginal = this.$store.getters.getStopsFeaturesInstantWithModifiedAU.find((st) => st.id === this.stop.id);
			}

			this.stop.observations = '';
			this.stop.entryPlanned = null;
			this.stop.departurePlanned = null;
			this.stop.bollardIni = stopOriginal.bollardIni;
			this.stop.bollardEnd = stopOriginal.bollardEnd;
			this.stop.inibollard = stopOriginal.inibollard;
			this.stop.endbollard = stopOriginal.endbollard;

			this.berth = {
				berth: stopOriginal.berthid,
				berthcode: stopOriginal.berthcode
			};

			this.berthType = {
				berthingtypecode: stopOriginal.berthingtype,
				docksideways: stopOriginal.docksideways,
				portstarboard: stopOriginal.portstarboard,
				bowstern: stopOriginal.bowstern
			};

			this.stop.berthingtype = this.berthType.berthingtypecode;
			this.stop.berthingtypename = this.berthType.berthingtypename;
			this.stop.docksideways = this.berthType.docksideways;
			this.stop.portstarboard = this.berthType.portstarboard;
			this.stop.bowstern = this.berthType.bowstern;

			this.stop.modified = false;

			var stops = this.updateStopsModifications(this.stop, true);
			this.stopsFeatureCreatorUpdated(stops);
		},

		stopsFeatureCreatorUpdated(stops) {
			if (!this.processVesselsRunning) {
				this.$puiEvents.$emit('stopfeaturecreator:updated', stops, true);
				this.$store.commit('setProcessVesselsRunning', true);
			}
		}
	},
	watch: {
		stop() {
			// Esto lo hacemos para cuando cambiamos de pestaña del mismo tipo.
			// Es decir, entre dos pestañas del tipo AuthPlanContentent, para refrescar los datos
			if (this.stopId != this.stop.id) {
				this.isStartTabBerth = true;
				this.isStartTabBerthForm = true;
				this.isStartTabBollardIni = true;
				this.isStartTabBollardEnd = true;
				this.isStartTabEtaAuth = true;
				this.isStartTabEtdAuth = true;
				this.setInitialData();
			}
		},
		authorization() {
			console.log(this.authorization);
		}
	}
};
</script>
